import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { isBrowser } from "../../context/ApolloContext"
import { isLoadedScript } from "../../utils/additionalScriptHelpers"

import SeoComp from "../../components/SeoComp"
import Footer from "../../components/Layout/Footer"
import { useLocation } from "@reach/router"
import ShowroomBottomSection from "../../components/ShowroomBottomSection"

const showroom = ({ data, intl }) => {
  const { pathname } = useLocation()

  const CalendarScript = () => {
    let srcUrl = "https://assets.calendly.com/assets/external/widget.js"

    if (!isLoadedScript(srcUrl)) {
      ;(function() {
        var script = document.createElement("script")
        script.async = true
        script.src = srcUrl
        document.body.appendChild(script)
      })()
    } else {
      Calendly.initInlineWidget({
        url: "https://calendly.com/showroom-percko/20min?hide_gdpr_banner=1",
        parentElement: document.getElementById("calendly-embed"),
      })
    }
  }

  useEffect(() => {
    if (
      process.env &&
      process.env.GATSBY_ACTIVE_ENV != "eur" &&
      intl.locale != "fr"
    ) {
      navigate(`/${intl.locale}/`)
    }
    if (
      (process.env.GATSBY_ACTIVE_ENV === "eur" ||
        process.env.GATSBY_ACTIVE_ENV === "general") &&
      intl.locale === "fr"
    ) {
      isBrowser && CalendarScript()
    }
  }, [intl.locale, pathname])

  return (
    process.env &&
    process.env.GATSBY_ACTIVE_ENV === "eur" &&
    intl.locale === "fr" &&
    data.allDirectusShowroomPageTranslation.nodes[0] && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "showroom",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />
        {data.allDirectusShowroomPageTranslation.nodes[0].top_section && (
          <TopSection>
            {data.allDirectusShowroomPageTranslation.nodes[0].top_section
              .image && (
              <ImageBox>
                <Image
                  src={
                    data.allDirectusShowroomPageTranslation.nodes[0].top_section
                      .image
                  }
                  alt={
                    data.allDirectusShowroomPageTranslation.nodes[0].top_section
                      .alt
                      ? data.allDirectusShowroomPageTranslation.nodes[0]
                          .top_section.alt
                      : ""
                  }
                />
              </ImageBox>
            )}
            <TopSectionWrapper style={{ flexDirection: "column" }}>
              {data.allDirectusShowroomPageTranslation.nodes[0].top_section
                .title && (
                <Title
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusShowroomPageTranslation.nodes[0]
                        .top_section.title,
                  }}
                />
              )}
              {data.allDirectusShowroomPageTranslation.nodes[0].top_section
                .text && (
                <TextBox
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusShowroomPageTranslation.nodes[0]
                        .top_section.text,
                  }}
                />
              )}
            </TopSectionWrapper>
            {data.allDirectusShowroomPageTranslation.nodes[0].top_section
              .second_image && (
              <BottomImageBox>
                <Image
                  src={
                    data.allDirectusShowroomPageTranslation.nodes[0].top_section
                      .second_image
                  }
                  alt={
                    data.allDirectusShowroomPageTranslation.nodes[0].top_section
                      .alt
                      ? data.allDirectusShowroomPageTranslation.nodes[0]
                          .top_section.alt
                      : ""
                  }
                />
              </BottomImageBox>
            )}
          </TopSection>
        )}
        <TopSection>
          <CalendarBox>
            <div
              className="calendly-inline-widget"
              id="calendly-embed"
              data-url="https://calendly.com/showroom-percko/20min?hide_gdpr_banner=1"
              style={{ height: "690px" }}
            ></div>
          </CalendarBox>
        </TopSection>

        {data.allDirectusShowroomPageTranslation.nodes[0].bottom_section && (
          <ShowroomBottomSection
            data={
              data.allDirectusShowroomPageTranslation.nodes[0].bottom_section
            }
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const TopSection = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ImageBox = styled.div`
  width: 100%;
  display: flex;
`

const BottomImageBox = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1024px) {
    display: none;
  }
`

const Image = styled.img`
  width: 100%;
  display: flex;
  margin: 0px;
  object-fit: cover;

  @media (max-width: 1200px) {
    object-position: 30% center;
  }

  @media (max-width: 1024px) {
    object-position: top center;
  }
`

const TopSectionWrapper = styled.div`
  width: 100%;
  min-width: 630px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e7f0f3;

  @media (max-width: 1200px) {
    min-width: 600px;
  }

  @media (max-width: 1024px) {
    min-width: 50%;
  }
`

const Title = styled.h1`
  font-family: "Museo";
  font-weight: 900;
  font-size: 40px;
  line-height: 1;
  margin: 0px 0px 10px;
  text-align: center;

  span {
    display: block;
  }

  @media (max-width: 1200px) {
    font-size: 28px;
  }

  @media (max-width: 600px) {
    font-size: 22px;
  }
`

const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-family: "Gotham Book";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    margin: 0px 0px 10px;
  }

  p:last-child {
    margin: 0px;
  }
`

const CalendarBox = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding: 20px;

  .calendly-inline-widget {
    width: 100%;
    min-width: 1000px;
    min-height: 690px;

    @media (max-width: 992px) {
      min-width: 320px;
      min-height: 1190px;
    }

    @media (max-width: 690px) {
      min-height: 1090px;
    }

    @media (max-width: 480px) {
      min-height: 1150px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    margin: 0px;
  }
`

export const query = graphql`
  query($lang: String!) {
    allDirectusShowroomPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        top_section {
          image
          second_image
          alt
          title
          text
        }
        bottom_section {
          section_title
          bottom_text
          list {
            name
            image
            alt
            video
            title
            text
            top_text
            button_text
            button_url
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "showroom" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(showroom)
